<template>
  <section v-if="Object.keys(pricing).length" id="pricing-plan">
    <!-- title and radio button -->
    <b-form-group
      class="d-flex align-items-center justify-content-center mt-1 mb-3"
    >
      <b-form-radio-group
        v-model="subscriptionType"
        :options="options"
        button-variant="outline-primary"
        size="lg"
        name="radio-btn-outline"
        buttons
      />
    </b-form-group>
    <!--/ title and radio button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row>
          <b-col v-for="plan in pricing" :key="plan.id" :md="pricingCols">
            <b-card align="center">
              <h3>{{ plan.title }}</h3>
              <b-card-text>
                {{ plan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary"
                    >€</sup
                  >
                  <template v-if="monthlyPlanShow">
                    <span
                      class="pricing-basic-value font-weight-bolder text-primary"
                      >{{ plan.subscriptionAmount }}</span
                    >
                    <sub
                      class="pricing-duration text-body font-medium-1 font-weight-bold"
                      >/{{ $t('month') }}</sub
                    >
                  </template>
                  <template v-else>
                    <span
                      class="pricing-basic-value font-weight-bolder text-primary"
                      >{{ plan.yearlySubscription }}</span
                    >
                    <sub
                      class="pricing-duration text-body font-medium-1 font-weight-bold"
                      >/{{ $t('year') }}</sub
                    >
                  </template>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <div class="d-inline-block" v-html="plan.description" />

              <!-- <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.basicPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group> -->
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-if="plan.monthlySubscription >= subscriptionAmount"
                :variant="
                  plan.id === subscriptionId
                    ? 'outline-success'
                    : 'outline-primary'
                "
                :to="'/new-payment/' + plan.id + '/' + subscriptionType"
                block
                class="mt-2"
              >
                {{
                  plan.id === subscriptionId
                    ? $t('Your current plan')
                    : $t('Upgrade')
                }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <!-- pricing faq -->
    <!-- <div class="pricing-faq">
      <h3 class="text-center">
        Συχνές Ερωτήσεις (FAQ)
      </h3>
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >

            <app-collapse-item
              v-for="(data,index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div> -->
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormRadioGroup,
  // BImg, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'

import { getSubscription } from '@/auth/utils'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCardText,
    // BListGroup,
    // BListGroupItem,
    BFormGroup,
    BFormRadioGroup,
    BRow,
    BCol,
    BCard,
    // BBadge,
    // BImg,
    // AppCollapseItem,
    // AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      subscriptionType: 'monthly',
      pricing: {},
    }
  },
  computed: {
    options() {
      return [
        { text: this.$t('Monthly'), value: 'monthly' },
        { text: this.$t('Annually'), value: 'annually' },
      ]
    },
    monthlyPlanShow() {
      return this.subscriptionType === 'monthly'
    },
    pricingCols() {
      if (this.pricing.length > 3) {
        return 3
      }
      if (this.pricing.length > 2) {
        return 4
      }
      return 6
    },
    subscriptionId() {
      return getSubscription().id
    },
    subscriptionAmount() {
      return getSubscription().monthlySubscription
    },
  },
  created() {
    this.$store.dispatch('app-common/fetchSubscriptions').then((response) => {
      this.pricing = response.data
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
